import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
import Code from '../components/code';
import Caption from '../components/Caption';
import LittleImg from '../components/LittleImg';
export const _frontmatter = {
  "title": "Mastery through Deliberate Practice",
  "date": "2020-06-01",
  "promo": "grids",
  "description": "Escape from tutorial hell",
  "color": "#59c7cf"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`A new student of mine recently shared his code-learning journey so far: he had watched countless videos on web development. But whenever he'd go to actually build something himself he couldn't remember how most of it worked. The smallest snag and he was stumped. He constantly felt frustrated and discouraged that all his time and effort spent wasn't translating into to actual skills. He wondered if he was even cut out for this. He was `}<strong parentName="p">{`stuck in tutorial hell`}</strong>{`.`}</p>
    <h2>{`Learn by doing`}</h2>
    <p>{`Whenever I crack open a new board game I've never played before, I like to watch a video of someone experienced with the game explaining its concepts and rules. I read through the manual, setup the board, and try it out myself. Without fail, I get stuck and have to google for answers to my many questions:`}</p>
    <p><em parentName="p">{`"How do the monsters select a target?"`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`"Do you still retaliate if you're dead?"`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`"What do curse cards do?"`}</em></p>
    <p>{`If it's a complex (and amazing) game like `}<a parentName="p" {...{
        "href": "https://boardgamegeek.com/boardgame/174430/gloomhaven"
      }}>{`Gloomhaven`}</a>{` then it's going to take dozens of game nights before everything really clicks. Watching more tutorials isn't going to help at this point— you've just gotta play the game.`}</p>
    <p>{`Learning to code is the same way. The concepts are abstract and the rules are complex. New tech (like expansion packs) are constantly released. You don't need one more tutorial— you've just gotta play the game. Use the tech yourself. Build something (a lot of things) with it. This is the `}<em parentName="p">{`only way`}</em>{` you'll master it. It's the `}<em parentName="p">{`only way`}</em>{` you're ever going to get to the point where you can just sit down and work, without having to constantly stop and reference the rulebook. And that's when the `}<em parentName="p">{`real`}</em>{` fun begins.`}</p>
    <h2>{`The process of learning`}</h2>
    <p>{`Before you can learn anything you must first `}<a parentName="p" {...{
        "href": "https://josebrowne.com/on-coding-ego-and-attention/"
      }}>{`stop thinking about yourself entirely`}</a>{`. Then once you begin to learn something, you start by loading information into your short-term memory. This is where reading blog posts, following tutorials etc is helpful. But don't stop there. And especially don't move on to something else too soon. Trying to cram a whole new topic into your overburdened prefrontal cortex isn't very effective. Your short-term storage centers only have so much capacity. Like a computer's RAM, if it runs out of space then new data will overwrite previous data.`}</p>
    <p>{`Before moving on it's important to convert that fresh information into something more stable and long-term. For this you have two options: declarative memory and procedural memory.`}</p>
    <h3>{`Declarative memory`}</h3>
    <p>{`When you intentionally memorize information, your brain transfers that data via the hippocampus to the temporal cortex for safekeeping. This is an `}<em parentName="p">{`explicit`}</em>{`, `}<em parentName="p">{`declarative`}</em>{` memory— you intentionally allocated space for it. Examples of this process would be memorizing CSS properties, or taking notes about JavaScript Array methods.`}</p>
    <p>{`To be useful to you later, this type of memory has to be re-loaded back into working (short-term) memory. Similar to loading data from a computer hard drive back into RAM. This process is relatively slow and takes effort.`}</p>
    <h3>{`Procedural memory`}</h3>
    <p>{`There is a much better long-term option for skills like coding: the cerebellum (and friends). Unlike memorization, `}<em parentName="p">{`procedural`}</em>{` memories arrive here `}<em parentName="p">{`implicitly`}</em>{`— on their own, often unintentionally. The process happens automatically simply by `}<strong parentName="p">{`doing`}</strong>{` something. Procedural memory is formed every time you set a CSS property and watch the results. Every time you write a function implementation and check the result it returns. `}</p>
    <p>{`Every time you write (or edit) code, you create (or strengthen) procedural memories.`}</p>
    <p>{`Recall of procedural memory is instant, unconscious, and effortless. Right now you are reading this post without having to remember how to read. Later you'll write an email or slack message without having to remember how to type. At this level the skill becomes natural, effortless. And it's completely possible to get there with coding skills.`}</p>
    <p>{`Procedural memory is also sometimes called `}<em parentName="p">{`muscle memory`}</em>{`, because when it was first discovered it was initially believed to only apply to motor skills (e.g. driving a car, playing the piano). But studies of people with amnesia (who are unable to form declarative memory) has broadened our understanding.`}</p>
    <p>{`Procedural memory is the superior long-term storage option, and creating it in students for coding skills is the primary goal of `}<a parentName="p" {...{
        "href": "/post/mastery-games-platform/"
      }}>{`Mastery Games`}</a>{`.`}</p>
    <h3>{`Which memory drive are you using?`}</h3>
    <p>{`Think of the last time you sat down to code something up. Was it effortless? Or did you have to work to remember how? Or did you have to work to remember where you might have seen the answer before as you go look it up? This simple exercise will reveal to you which long-term memory drive your coding skills are `}<em parentName="p">{`currently`}</em>{` residing in. `}</p>
    <p>{`But don't get down on yourself if you realize you're mostly using declarative memory! We are all that way, in a lot of areas! The takeaway though is that you don't have to settle for that for your coding skills. You can have it better. You can transform yourself into a beast of a procedural memory machine.`}</p>
    <p>{`How do we go about transitioning to this ideal type of memory?`}</p>
    <h2>{`Deliberate practice`}</h2>
    <p>{`Doing something on repeat will form procedural memories. But mindless repetition isn't the most effective strategy. Deliberate practice is the key. With deliberate practice you pick a `}<em parentName="p">{`single`}</em>{` thing you'd like to improve, and you do it. Over and over, making sure you have good feedback to know whether you're doing it correctly.`}</p>
    <p>{`In `}<a parentName="p" {...{
        "href": "https://gridcritters.com"
      }}>{`Grid Critters`}</a>{` students spend an entire learning session on a single CSS Grid property like `}<inlineCode parentName="p">{`grid-auto-flow`}</inlineCode>{`. They learn the ins and outs of the property, then solve challenge after challenge using it as the key component of the layout.`}</p>
    <p>{`Most courses and tutorials don't include the deliberate practice component, but you can set it up for yourself. It's not too complicated:`}</p>
    <ol>
      <li parentName="ol">{`Narrow in on one tiny aspect to improve on. E.g. pick `}<inlineCode parentName="li">{`grid-template-columns`}</inlineCode>{`, not all of CSS.`}</li>
      <li parentName="ol">{`Learn about it from others. (short-term memory)`}</li>
      <li parentName="ol">{`Practice it. Play with it. Build everything you possibly can with it. (long-term procedural memory)`}</li>
    </ol>
    <p>{`Do this consistently and before you know it you'll notice yourself gaining the `}<a parentName="p" {...{
        "href": "/post/power-of-mastery"
      }}>{`perks of leveling up`}</a>{`.`}</p>
    <p>{`Say you want to learn `}<a parentName="p" {...{
        "href": "https://mastery.games/post/position-sticky/"
      }}>{`CSS position sticky`}</a>{`. Start with blog posts, videos or the great resources on `}<a parentName="p" {...{
        "href": "https://web.dev/"
      }}>{`web.dev`}</a>{` and `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/"
      }}>{`MDN`}</a>{`. Great! Your short-term memory is loaded up. Now go build a lot of things with what you just learned. Find layouts you think look nice and see if you can recreate them yourself. Direct that fresh information straight into your procedural memory centers. The more times you do it, the better.`}</p>
    <p>{`In my `}<a parentName="p" {...{
        "href": "https://reactpodcast.simplecast.com/episodes/95"
      }}>{`guest episode of React Podcast`}</a>{` I shared the story of how I used deliberate practice to get better at StarCraft2. You can use deliberate practice to get quite good at literally anything.`}</p>
    <p>{`The student I told you about at the beginning of this post wrote me back a few weeks later, after using the deliberate practice I provide in my Mastery Games. He was ecstatic to tell me that `}<a parentName="p" {...{
        "href": "https://flexboxzombies.com"
      }}>{`CSS Flexbox`}</a>{` and `}<a parentName="p" {...{
        "href": "https://gridcritters.com"
      }}>{`CSS Grid`}</a>{` had finally `}<em parentName="p">{`clicked`}</em>{` for him. Not only had he escaped from tutorial hell, he now had the confidence to go master anything else himself. That's the power of using deliberate practice to form long-term procedural memory.`}</p>
    <p>{`By the way, `}<a parentName="p" {...{
        "href": "https://gridcritters.com?ref=power-of-mastery"
      }}>{`Grid Critters`}</a>{` is currently on a huge sale. Save a ton of time creating your own deliberate practice sessions for CSS Grid. I've got the full Mastery Game experience ready and waiting for you.`}</p>
    <h2>{`Resources`}</h2>
    <p><a parentName="p" {...{
        "href": "https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0191654"
      }}>{`Not explicit but implicit memory is influenced by individual perception style`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://human-memory.net/explicit-implicit-memory/#:~:text=Declarative%20memories%20are%20encoded%20by,the%20temporal%20cortex%20and%20elsewhere"
      }}>{`Declarative (explicit) & procedural (implicit) memory`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://jamesclear.com/beginners-guide-deliberate-practice"
      }}>{`The Beginner's Guide to Deliberate Practice`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Procedural_memory"
      }}>{`Procedural memory`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      